import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout2 from "../components/layout2"
import Footer from '../components/footer'
import './../css/thoughts.css'
import { Helmet } from "react-helmet"
import './../css/button.css'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

class Thoughts extends React.Component {
  constructor(){
    super();
    this.state = {
        data: [],
        data_loaded:false,
        current_message:null,
    }
    this.changeMessage = this.changeMessage.bind(this);
}

changeMessage() {
   this.setState({current_message: this.state.data[Math.floor(Math.random() * this.state.data.length)]});
 }

  componentDidMount () {
    fetch("https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/thoughts.json?alt=media")
        .then( (response) => {
            return response.json() })
                .then( (json) => {
                    this.setState({data: json, data_loaded:true, current_message: json[Math.floor(Math.random() * json.length)]})
                    console.log(this.state)
                })
}
render() {
  if (this.state.data_loaded) {
  return (
    <Layout2>
    <StaticQuery
      query={graphql`
        query {
          fileName: file(relativePath: { eq: "img/projects/thoughtshero.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Helmet title="Brett Bejcek: A Thought A Day">
      <meta name="description" content="Get inside my head. See what's been on my mind." />
      <meta name="image" content={data.fileName.childImageSharp.fluid.src} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Brett Bejcek: A Thought A Day" />
      <meta property="og:description" content="Get inside my head. See what's been on my mind." />
      <meta property="og:image" content={data.fileName.childImageSharp.fluid.src} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content="Brett Bejcek: A Thought A Day" />
      <meta name="twitter:description" content="Get inside my head. See what's been on my mind." />
      <meta name="twitter:image" content={data.fileName.childImageSharp.fluid.src} />
        </Helmet>
      )}
    />
    <div className = 'borderTransition' css={{width:`100%`, marginLeft:`auto`, marginRight:`auto`, maxWidth:`700px`, padding:`30px`, position:'relative'}}>
        <div className = 'borderTransition' css = {{minHeight:`10rem`, borderRadius:`20px`, background:`#7d4aa3`, marginBottom:`2rem`, padding:`2rem`, color:`white`}}><h1 >Thought a day</h1><p css={{color:`white`}}>I have been journaling 3 pages a day and synthesizing my takeaways from each day. Go on a random or organized walk and get inside my head.</p></div>
        <h1> Random Walk</h1>
    <div className = 'borderTransition' style = {{transition:`2s`, border:`#7d4aa3 solid 4px`, borderRadius:`20px`, marginBottom:`2rem`}} >
    <div className = 'borderTransition' style = {{padding:`25px`}}>
    <div className = 'borderTransition' style = {{marginBottom:`10px`, width:`100%`, display:`flex`}}>
    </div>
    <b>{this.state.current_message.date}</b>
    <br/>
    {this.state.current_message.thought}
    </div>
    <div style={{width:`100%`, textAlign:`center`}}>
    <button className='button' style = {{marginLeft:`auto`, marginRight:`auto`,backgroundColor: `#7d4aa3`, textDecoration:`none`, border: `none`, borderRadius:`20px`, color:`white`,fontSize:`normal`, height:`3rem`, marginBottom:`30px`}} onClick={this.changeMessage}>get another random thought</button>
    </div>
    </div>
    <h1> Organized Walk</h1>
    <VerticalTimeline>
    {this.state.data.map(item => 

  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'white', color: 'black', border:`gray 4px solid` }}
    contentArrowStyle={{ borderRight: '15px solid  gray' }}
    date={item.date}
    iconStyle={{ display:`none` }}
  >
    <p>
      {item.thought}
    </p>
  </VerticalTimelineElement>)}
</VerticalTimeline>
    <div className = 'help' css = {{'@media (max-width: 750px)':{width:`110%`, marginLeft:`-5%`}}}>
    <Footer/>
    </div>
    </div>

    </Layout2>
)
}
else return(<Layout2><div/></Layout2>)
}

}

export default Thoughts;
